@import '../../../assets/styles/theme';

.header {
  z-index: 7;
  position: sticky;
  top: 0;
}

.header-shade-area {
  filter: drop-shadow($text-shade-S);
}

.header-block {
  padding: ($space / 1.4) $space !important;
  background-image: linear-gradient(
    to top right,
    transparentize($accent, 0.97),
    transparent
  );
  background-size: 100% 100%;
  background-position: -100% 50%;
  box-shadow: none;
}

.header-logo {
  margin-top: -4px;
  margin-bottom: -8px;
  width: $font-L * 1.5;
  height: $font-L * 1.5;
  box-shadow: none !important;
}

.header-separator {
  padding: 0 ($space / 2);
  font-size: $font-L;
  opacity: 0.07;

  @media (max-width: $mobile) {
    visibility: hidden;
    padding: 0 $space / 2;
  }
}

.header-wordmark {
  font-size: $font-L;
  letter-spacing: -1px;
  text-decoration: none !important;
}

.header-nav-max-view {
  display: block;
}
.header-nav-min-view {
  display: none;
}
@media (max-width: $mobile * 1.5) {
  .header-nav-max-view {
    display: none;
  }
  .header-nav-min-view {
    display: block;
  }
}

.header-nav-links {
  margin: (-$space * 1.5) 0;
  padding-right: $space / 2;
  height: $space * 3;
  text-decoration: none !important;

  .flex {
    flex-direction: column;
    height: inherit;

    .center {
      margin: auto;
      padding: 0 ($space);
    }
  }
}

.header-nav-btn {
  margin-top: -24px !important;
  margin-bottom: -24px !important;
  padding: 0 !important;
  width: 48px !important;
  height: 48px !important;
  border: 0 !important;
  background-image: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  color: $primary !important;
}

.header-nav-drop-vault {
  position: absolute;
  top: $space;
  right: $space;
  height: 0;
  width: 0;
}

.header-nav-drop {
  .gerami-menu {
    max-height: 80vh;
  }
}

.header-nav-drop-items {
  padding: $space !important;
  width: $space * 14 !important;
}
