@import '../../../assets/styles/theme';

@keyframes DefaultPromotionBg {
  0% {
    background-color: darken($primary, 14%);
  }

  100% {
    background-color: darken($accent, 14%);
  }
}

.default-promotion {
  background-image: linear-gradient(
    to top right,
    transparentize(white, 0.58),
    transparent
  );
  color: $bg;
  animation-name: DefaultPromotionBg;
  animation-duration: 7s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.default-promotion-logo {
  filter: drop-shadow($text-shade-S);
}
